/*
 *
 *  session remotes
 *
 */

import axios from "axios";
import api from "../../helpers/api";
import { decryption, encryption } from "cipher/cipher";

export async function verifySession() {
  return await api
    .get(`/rest/user/session`)
    .then(async (response) => {
      if (!response.data || (response.data && response.data.error)) {
        return Promise.reject("Session Timout");
      }
      const { data = {} } = response;
      let decryptData = await decryption(data);
      return decryptData;
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function logIn(record) {
  return await api
    .post(record.remember_me ? `/user/login` : `/user/login`, record)
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => Promise.reject(error));
}

export async function logOut() {
  return await api
    .post(`/rest/users/logout`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function signUp(record) {
  return await api
    .post(`/user/signup`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function forgotPassword(record) {
  return await api
    .post(`/rest/user/forget-password`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function changePassword(record) {
  return await api
    .post(`/rest/user/reset-password?from=reset-paasword`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function resetPassword(record) {
  return await api
    .post(`/rest/user/reset-password?from=forget-paasword`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function twoFactorAuthentication(record) {
  return await api
    .post(`/rest/user/two-factor`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function sendVerificationCode(record) {
  let encryptData = await encryption(record);
  return await api
    .post(`/user/send-verification-code`, { data: encryptData })
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      response.data = decryptData
      return response.data
    })
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function otpVerification(record) {
  return await api
    .post(`/user/verification`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {object} record
 */
export async function updateUserProfile(record) {
  let encryptData = await encryption(record);
  return await api
    .put(`/user/${record.id}`, { data: encryptData })
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function resendOtp(record) {
  let encryptData = await encryption(record);
  return await api
    .post(`/user/resend-otp`, { data: encryptData })
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      response.data = decryptData
      return response.data
    })
    .catch((error) => Promise.reject(error));
}

export async function sendUserOtp(record) {
  let encryptData = await encryption(record);
  return await api
    .post(`/rest/user/profile-verification/send-code`, { data: encryptData })
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      response.data = decryptData
      return response.data
    })
    .catch((error) => Promise.reject(error));
}

export async function verifyUserOtp(record) {
  let encryptData = await encryption(record);
  return await api
    .post(`/rest/user/profile-verification/verify`, { data: encryptData })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {string} mainUrl
 * @param {object} geoLocation
 * @returns
 */
function commomFun(mainUrl, geoLocation) {
  if (geoLocation) {
    const coords = geoLocation?.coordinates;
    let queryParams = "?";
    if (coords?.lat || coords?.lng || geoLocation?.kiloMeter) {
      // Include parameters if any of the conditions are true
      if (coords?.lat) {
        queryParams += `latitude=${coords.lat}&`;
      }
      if (coords?.lng) {
        queryParams += `longitude=${coords.lng}&`;
      }
      if (geoLocation?.kiloMeter) {
        queryParams += `radius=${geoLocation.kiloMeter}&`;
      }
    }

    return `${mainUrl}${queryParams}`;
  } else {
    return `${mainUrl}?`;
  }
}

export async function getAllbanner(loggedIn, geoLocation) {
  let url = loggedIn
    ? commomFun(`/rest/banner`, geoLocation)
    : commomFun(`/rest/public-banner`, geoLocation);
  return await api
    .get(url)
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => {
      console.log(error);
      Promise.reject(error)
    });
}

export async function uploadUserProfile(record) {
  return await api
    .post(`/rest/user/profile-upload`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {string} loggedIn
 * @returns
 */
export async function loadPostCategories(loggedIn) {
  const url = loggedIn
    ? `/rest/public/post-categories`
    : `/rest/public/post-categories`;
  return await api
    .get(`${url}`)
    .then(async (response) => {
      // response && response.data
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => Promise.reject(error));
}

const remotes = {
  verifySession,
  logIn,
  logOut,
  signUp,
  forgotPassword,
  changePassword,
  resetPassword,
  twoFactorAuthentication,
  sendVerificationCode,
  otpVerification,
  updateUserProfile,
  resendOtp,
  sendUserOtp,
  verifyUserOtp,
  getAllbanner,
  uploadUserProfile,
  loadPostCategories,
};

export default remotes;
