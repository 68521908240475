// import React, { useState } from "react";
// import Fab from "@mui/material/Fab";
// import Styles from "./styles";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import Footer from "components/Footer";
// import { Grid } from "@mui/material";

import whatsappIcon from '../../assets/socialIcons/social.png';
// import Draggable from 'react-draggable'; // The default
// import { useRef } from "react";

// function FooterBtn() {
//     const classes = Styles();

//     const [startX, setStartX] = useState(null);
//     const [startY, setStartY] = useState(null);
//     const [isDragging, setIsDragging] = useState(false);


//     const eventControl = (event) => {
//         if (event.type === 'mousemove' || event.type === 'touchmove') {
//             setIsDragging(true)
//         }

//         if (event.type === 'mouseup' || event.type === 'touchend') {
//             setTimeout(() => {
//                 setIsDragging(false);
//             }, 100);

//         }
//     }

//     const handleTouchStart = event => {
//         const touch = event.touches[0];
//         setStartX(touch.clientX);
//         setStartY(touch.clientY);
//     };

//     const handleTouchEnd = event => {
//         const touch = event.changedTouches[0];
//         const endX = touch.clientX;
//         const endY = touch.clientY;
//         const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
//         if (distance < 5) {
//             window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
//         }
//     };

//     return (
//         <Draggable handle=".handle" bounds="body" onDrag={eventControl} onStop={eventControl}>
//             <div className="handle" style={{
//                 position: "fixed",
//                 bottom: "75px",
//                 right: "35px",
//                 zIndex: "999",
//             }}>
//                 <img draggable={false} src={whatsappIcon} width={50} height={50} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
//                     // onTouchEnd={(e) => {
//                     //     e.preventDefault();
//                     //     if (!isDragging) {
//                     //         window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
//                     //     }
//                     // }}
//                     onClick={(e) => {
//                         e.preventDefault();
//                         if (!isDragging) {
//                             window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
//                         }
//                     }} />
//             </div>
//         </Draggable>
//     );
// }

// export default FooterBtn;


import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
// import "./styles.css";

function App() {
    const [positions, setPositions] = useState({ x: 0, y: 0 });
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);

    const nodeRef = useRef();

    useEffect(() => {
        const savedPosition = JSON.parse(localStorage.getItem("position"));
        if (savedPosition) {
            setPositions(savedPosition);
        }
        setHasLoaded(true);
    }, []);

    const eventControl = (event) => {
        if (event.type === 'mousemove' || event.type === 'touchmove') {
            setIsDragging(true)
        }

        if (event.type === 'mouseup' || event.type === 'touchend') {
            setTimeout(() => {
                setIsDragging(false);
            }, 100);

        }
    }

    const handleStop = (event, data) => {

        if (event.type === 'mousemove' || event.type === 'touchmove') {
            setIsDragging(true)
        }

        if (event.type === 'mouseup' || event.type === 'touchend') {
            setTimeout(() => {
                setIsDragging(false);
            }, 100);
        }

        const { x, y } = data;
        localStorage.setItem("position", JSON.stringify({ x, y }));
        setPositions({ x, y });
    };

    const handleTouchStart = event => {
        const touch = event.touches[0];
        setStartX(touch.clientX);
        setStartY(touch.clientY);
    };

    const handleTouchEnd = event => {
        const touch = event.changedTouches[0];
        const endX = touch.clientX;
        const endY = touch.clientY;
        const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
        // console.log({endX, endY});
        
        if (distance < 5) {
            window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
        }
    };

    return hasLoaded ? (
        <Draggable
            bounds="body"
            nodeRef={nodeRef}
            position={positions} // Use 'position' instead of 'defaultPosition'
            onDrag={eventControl}
            onStop={handleStop}
        >
            <div
                ref={nodeRef}
                style={{
                    background: "transparent",
                    height: "50px",
                    textAlign: "center",
                    fontFamily: "cursive",
                    width: "50px",
                    cursor: "move",
                    zIndex: "999",
                    position: "fixed",
                    bottom: "75px",
                    right: "35px",
                }}
            >
                <img draggable={false} src={whatsappIcon} width={50} height={50} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                loading="lazy"
                    // onTouchEnd={(e) => {
                    //     e.preventDefault();
                    //     if (!isDragging) {
                    //         window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
                    //     }
                    // }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!isDragging) {
                            window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
                        }
                    }} />
            </div>
        </Draggable>
    ) : null;
}

export default App;
