/**
 *
 * Cookies ModelForm
 *
 */

import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Modal,
  Fade,
  Paper
} from "@mui/material";
import Styles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Field, reduxForm } from "redux-form";
import SwitchField from "components/SwitchField";
import lock from "assets/images/cookies/lock.svg";

/**
 *
 * @param {object} props
 * @returns
 */
function CookiesModelForm(props) {
  const {
    show,
    onClose,
    paperClassName,
    children,
    handleSubmit,
    acceptAllCookies,
  } = props;
  const classes = Styles();
  const [showModal, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
    if (onClose) onClose();
  };

  return (
    <Grid container>
      {children && children(() => setModalOpen(!showModal))}
      <Modal
        open={showModal || show || false}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        closeAfterTransition
      >
        <Fade in={showModal || show || false}>
          <Paper className={`${classes.paper} ${paperClassName}`}>
            <Grid item className={classes.closeContainer}>
              <CloseIcon onClick={closeModal} className={classes.closeIcon} />
            </Grid>
            <form onSubmit={handleSubmit} noValidate>
              <Grid container sx={{ gap: "15px !important" }}>
                <Grid item xs={12} className={classes.header}>
                  <Typography component="div" className={classes.title}>
                    {"Cookie Settings"}
                  </Typography>
                  <Typography component="div" className={classes.subTitle}>
                    {
                      "We use cookies, some of them are essential, others are optional. Learn more"
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.boxContainer}>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img src={lock} alt="lock" className={classes.strickLock} loading="lazy"/>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="div" className={classes.heading}>
                      {"Strictly Necessary"}
                    </Typography>
                    <Typography component="div" className={classes.subHeading}>
                      {
                        "These cookies are necessary for the website and can't be deactivated."
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.boxContainer}>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Field
                      name="marketing"
                      type="switch"
                      component={SwitchField}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="div" className={classes.heading}>
                      {"Marketing & Analytics"}
                    </Typography>
                    <Typography component="div" className={classes.subHeading}>
                      {
                        "These cookies can be set by our advertising partners through our website."
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.boxContainer}>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Field
                      name="preferences"
                      type="switch"
                      component={SwitchField}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="div" className={classes.heading}>
                      {"Preferences"}
                    </Typography>
                    <Typography component="div" className={classes.subHeading}>
                      {
                        "To individualize your content, we use tools that personalize your web experience."
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="button"
                    fullWidth
                    color="headerPrimary"
                    variant="contained"
                    className={classes.confirmBtn}
                    onClick={() =>
                      acceptAllCookies(
                        Object.assign(
                          {},
                          {
                            strictly: true,
                            marketing: true,
                            preferences: true,
                          },
                        ),
                      )
                    }
                  >
                    Accept All Cookies
                  </Button>
                </Grid>{" "}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    color="cancelBtn"
                    variant="contained"
                    className={classes.acceptBtn}
                    onClick={handleSubmit}
                  >
                    Save Settings
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Fade>
      </Modal>
    </Grid>
  );
}

export default reduxForm({
  form: "CookiesModelForm",
  enableReinitialize: true,
  touchOnChange: true,
})(CookiesModelForm);
